<template>
    <div>
        <div v-if="isAuthenticated" class="layout">
            <OrganismAppHeader/>
            <div v-if="!isLoading" id="my-account" class="my-account container">
                <OrganismBreadcrumbs :items="breadcrumbs"/>

                <template v-if="userData">
                    <AtomAccountGreeting
                        v-if="showTitle"
                        :customer-name="`${userData.firstName} ${userData.lastName}`"
                    />
                    <MoleculeAccountTabs
                        v-model:activeIndex='activeTabIndex'
                        :items='tabItems'
                        :class="{'account-tabs': true, 'account-micro-frontend-carts-tabs': !showTitle }"
                        :line-style="true"
                        @tab-changed='update'
                    />
                    <slot />
                </template>
            </div>
            <AtomSpinner v-if="isLoading"/>
            <OrganismAppFooter class="account-footer"/>
            <OrganismMatrixModal />
            <MoleculeToasts />
            <MoleculeTransfers />
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { BreadcrumbsItem } from '~/composables/types/api/searchDiscover/categoryTreeTypes';

const { isAuthenticated, getUserData, userData, logout } = useUser();
const { $can, $t, $hasRole, $userFlow } = useNuxtApp();
const title = ref('');
const route = useRoute();
const toasts = useToasts();
const isLoading = ref(false);
const cartsTabIndex = 2;
const config = useRuntimeConfig();

if (!isAuthenticated.value) {
    logout();
    window.location.href = '/';
}

const tabItems = ref([
    { link: 'addresses', icon: 'home', activeIndex: 0 },
    { link: 'order-history', icon: 'shopping-cart', activeIndex: 1 },
    { link: 'carts', icon: 'basket', activeIndex: 2 },
    { link: 'condition-sheets', icon: 'conditions', activeIndex: 3 },
    { link: 'branch-clusters', icon: 'cluster', activeIndex: 4 },
    { link: 'order-details', icon: '', activeIndex: 1 },
]);

if ($hasRole(RoleTypes.SUPPLIER)) {
    tabItems.value = [
        { link: 'order-history', icon: 'shopping-cart', activeIndex: 0 },
        { link: 'carts', icon: 'basket', activeIndex: 1 },
    ];
}

if (!$can(PermissionTypes.ORDER) && !$hasRole(RoleTypes.MEMBER)) {
    tabItems.value = [
        { link: 'addresses', icon: 'home', activeIndex: 0 },
    ];
}

const activeTabIndex = ref(0);
const breadcrumbs: Ref<BreadcrumbsItem[]> = ref([{}]);

useHead({
    title: title
});
const showTitle = computed(() => (activeTabIndex.value !== cartsTabIndex));

const setBreadCrumbs = () => {
    breadcrumbs.value = [
        {
            name: `Vororder Portal`,
            link: `/`,
        },
        {
            name: $t(`General.${tabItems.value[activeTabIndex.value].link.replaceAll('-', '_')}`),
            link: `/${tabItems.value[activeTabIndex.value].link}`,
        }
    ];
};

const setActiveTab = () => {
    activeTabIndex.value = tabItems.value.filter((x:any) => route.path.indexOf(x.link) !== -1)?.[0]?.activeIndex || 0;

    if (route.path === NuxtLinkNameTypes.ACCOUNT_PAGE ||
        route.path === `${NuxtLinkNameTypes.ACCOUNT_PAGE}/`) {
        navigateTo({ path: NuxtLinkNameTypes.ADDRESSES_PAGE });
    }

    setBreadCrumbs();
    title.value = $t(`General.${tabItems.value[activeTabIndex.value].link.replaceAll('-', '_')}`);
};

const update = (i: number) => {
    navigateTo({ path: `${NuxtLinkNameTypes.ACCOUNT_PAGE}/${tabItems.value[i].link}` });
};

const redirectTo = (event: Event) => {
    if ('detail' in event) {
        navigateTo(event.detail?.toString());
    }
};

watch(() => route.path, () => {
    setActiveTab();
});

onMounted(async() => {
    isLoading.value = true;

    window.addEventListener('redirectTo', redirectTo);
    window.dataLayer = window.dataLayer || [];

    if (!activeTabIndex.value) {
        setActiveTab();
    }

    await getUserData();

    if (!userData.value?.id) {
        toasts.add('LOAD_ERROR');

        return;
    }

    if (config.public.userCentricsIsActive) {
        useUsercentricsCookieConsents();
    }

    $userFlow.loadUserFlow();
    isLoading.value = false;
});

onBeforeUnmount(() => {
    window.removeEventListener('redirectTo', redirectTo);
});
</script>

<style lang="scss" scoped>
.account-footer {
    margin-top: rem(56);
}

.account-tabs {
    margin-bottom: rem(32);

    &:deep(.text) {
        max-width: rem(144);
    }

    &:deep(.account-tabs__item) {
        min-width: rem(192);
    }
}

.account-micro-frontend-carts-tabs {
    margin: 0 0 rem(32);
}
</style>
